import React from 'react';
import Layout from '../../components/Layout';
import NavOne from '../../components/NavOne';
import { List, ThemeIcon } from '@mantine/core';
import PageHeader from '../../components/PageHeader';
import { CircleDashed } from 'tabler-icons-react';
import ClientStripe from '../../assets/images/circle-stripe.png';
import Footer from '../../components/Footer';

const GethealthCare = () => {
  return (
    <section className='about-one '>
      <img src={ClientStripe} className='about-one__circle' alt='' />
      <div className='container text-center'>
        <div className='block-title text-center'>
          <h2 className='block-title__title'>Getting Healthcare in LUTH</h2>
        </div>
        <p className='about-one__text'>
          We are delighted about your interest in receiving medical care at the
          Lagos University Teaching Hospital (LUTH). Our team of experts are
          always ready to provide best in class services to meet your needs.
          <br />
          <br />
          LUTH is designated primarily as a referral centre that provides
          tertiary specialized services in diagnostics, dentistry, internal
          medicine and surgery. We however also run regular general out patient
          clinics several times weekly.
          <br />
          <br />
          If your desire is to access specialist care:
          <br />
          <List
            spacing='xs'
            size='sm'
            center
            icon={
              <ThemeIcon color='blue' size={24} radius='xl'>
                <CircleDashed size={16} />
              </ThemeIcon>
            }
          >
            <List.Item>
              ask your current care provider to refer you to the appropriate
              unit in LUTH
            </List.Item>            
            <List.Item>
              ask your current care provider to refer you to the appropriate
              unit in LUTH.
            </List.Item>
            <List.Item>
              present your referral to the appropriate clinic (clinic schedule
              can be access here)
            </List.Item>
            <List.Item>
              If your needs are urgent, kindly present to the emergency unit
              which is open 24/7.
            </List.Item>
          </List>
          <br />
          <br />
          If you require a general consultation, you may present to our General
          Outpatient Clinics where our specialist family physicians will be on
          hand to attend to your needs. You may be referred for further
          specialist consultation as need be.
          <br />
          <br />
          Thanks again for your interest. If you have further enquiries, kindly
          send an email to or call and we respond to you as soon as possible.
        </p>
      </div>
    </section>
  );
};

const AboutPage = () => {
  return (
    <Layout pageTitle='Patient Guide | LUTH'>
      <NavOne />
      <PageHeader title='' />
      {/* <Content /> */}
      <GethealthCare />
      <Footer />
    </Layout>
  );
};

export default AboutPage;
